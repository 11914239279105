/* eslint react/no-unknown-property: 0 */

import { Mesh, MeshStandardMaterial } from 'three'

import React from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { I3DModelProps } from '../types'
import useScreenTexture from 'hooks/useScreenTexture'

type GLTFResult = GLTF & {
  nodes: {
    Plane: Mesh
    Plane_1: Mesh
    Plane_2: Mesh
    Plane_3: Mesh
    Plane_4: Mesh
    Screen: Mesh
  }
  materials: {
    metal: MeshStandardMaterial
    ['matte black']: MeshStandardMaterial
    ['glass type 2']: MeshStandardMaterial
    glass: MeshStandardMaterial
    black: MeshStandardMaterial
  }
}

export default function TabletHorizontalModel({
  setRef = () => null,
  screenMedia,
  ...props
}: I3DModelProps) {
  const { nodes, materials } = useGLTF(
    '/assets/3dmodels/tablet/tablet_horizontal.glb',
  ) as GLTFResult
  const screenRef = useScreenTexture(screenMedia)

  return (
    <group ref={setRef} {...props} dispose={null}>
      <mesh geometry={nodes.Plane.geometry} material={materials.metal} />
      <mesh geometry={nodes.Plane_1.geometry} material={materials['matte black']} />
      <mesh geometry={nodes.Plane_2.geometry} material={materials.glass} />
      <mesh geometry={nodes.Plane_3.geometry} material={materials.black} />
      <mesh geometry={nodes.Plane_4.geometry} material={materials['glass type 2']} />
      <mesh geometry={nodes.Screen.geometry} material={materials.black}>
        <meshBasicMaterial color="black" />
        <meshBasicMaterial ref={screenRef} toneMapped={false} />
      </mesh>
    </group>
  )
}

useGLTF.preload('/assets/3dmodels/tablet/tablet_horizontal.glb')
