import {
  Vector3,
  Group,
  Camera,
  PerspectiveCamera,
  Shape,
  ShapeGeometry,
  Vector2,
  BufferAttribute,
  Texture,
  TextureLoader,
  VideoTexture,
  LinearFilter,
  LinearMipmapLinearFilter,
  sRGBEncoding,
} from 'three'

import { IScreenMedia } from 'public/assets/3dmodels/types'

const vectorPool: Vector3[] = [new Vector3(), new Vector3()]

export const toScreenPosition = (
  obj: Group,
  camera: Camera,
  windowWidth: number,
  windowHeight: number,
): Vector3 => {
  const vector = vectorPool[0]

  const widthHalf = 0.5 * windowWidth
  const heightHalf = 0.5 * windowHeight

  obj.updateMatrixWorld()
  vector.setFromMatrixPosition(obj.matrixWorld)
  vector.project(camera)

  vector.x = vector.x * widthHalf + widthHalf
  vector.y = -(vector.y * heightHalf) + heightHalf
  vector.z = 0

  return vector
}

export const toWorldPosition = (
  obj: Vector3,
  camera: Camera,
  windowWidth: number,
  windowHeight: number,
): Vector3 => {
  const vector = vectorPool[1]

  vector.set((obj.x / windowWidth) * 2 - 1, -(obj.y / windowHeight) * 2 + 1, 0)
  vector.unproject(camera)

  return vector
}

export function createRoundedRectShape(width: number, height: number, borderRadius: number): Shape {
  const shape = new Shape()
  const halfWidth = width / 2
  const halfHeight = height / 2

  shape
    .moveTo(-halfWidth + borderRadius, -halfHeight)
    .lineTo(halfWidth - borderRadius, -halfHeight)
    .quadraticCurveTo(halfWidth, -halfHeight, halfWidth, -halfHeight + borderRadius)
    .lineTo(halfWidth, halfHeight - borderRadius)
    .quadraticCurveTo(halfWidth, halfHeight, halfWidth - borderRadius, halfHeight)
    .lineTo(-halfWidth + borderRadius, halfHeight)
    .quadraticCurveTo(-halfWidth, halfHeight, -halfWidth, halfHeight - borderRadius)
    .lineTo(-halfWidth, -halfHeight + borderRadius)
    .quadraticCurveTo(-halfWidth, -halfHeight, -halfWidth + borderRadius, -halfHeight)

  return shape
}

export function generateRoundedRectUV(
  geometry: ShapeGeometry,
  width: number,
  height: number,
): void {
  const uv = []
  const positionAttribute = geometry.getAttribute('position') as BufferAttribute
  const vertexCount = positionAttribute.count
  const halfWidth = width / 2
  const halfHeight = height / 2

  for (let i = 0; i < vertexCount; i++) {
    const x = positionAttribute.getX(i)
    const y = positionAttribute.getY(i)
    uv.push(new Vector2((x + halfWidth) / width, (y + halfHeight) / height))
  }

  geometry.setAttribute(
    'uv',
    new BufferAttribute(new Float32Array(uv.flatMap((v) => [v.x, v.y])), 2),
  )
}

export const getVideoTexture = ({ ref, src }: IScreenMedia): Texture | null => {
  if (!ref?.current) return null

  const texture = new VideoTexture(ref.current)

  ref.current.src = src
  ref.current.load()

  texture.encoding = sRGBEncoding

  return texture
}

export const getImageTexture = ({ src }: IScreenMedia): Texture => {
  const loader = new TextureLoader()
  const texture = loader.load(src, (loadedTexture) => {
    loadedTexture.encoding = sRGBEncoding
    loadedTexture.minFilter = LinearMipmapLinearFilter
    loadedTexture.magFilter = LinearFilter
    loadedTexture.anisotropy = 16
    loadedTexture.needsUpdate = true
  })

  return texture
}

export const getScreenTexture = (screenMedia: IScreenMedia): Texture | null => {
  switch (screenMedia.type) {
    case 'video':
      return getVideoTexture(screenMedia)
    case 'image':
      return getImageTexture(screenMedia)
    default:
      return null
  }
}

export const calculateFrustumDimensions = (camera: PerspectiveCamera, distance: number) => {
  const aspectRatio = camera.aspect
  const vFOV = (camera.fov * Math.PI) / 180 // convert vertical field of view to radians
  const frustumHeight = 2.0 * distance * Math.tan(vFOV / 2) // calculate the visible height
  const frustumWidth = frustumHeight * aspectRatio // calculate the visible width

  return { frustumWidth, frustumHeight }
}

export const getUnitsPerPixel = (
  canvasWidth: number,
  canvasHeight: number,
  camera: PerspectiveCamera,
  distance: number,
) => {
  const { frustumWidth, frustumHeight } = calculateFrustumDimensions(camera, distance)
  const unitsPerPixelX = frustumWidth / canvasWidth
  const unitsPerPixelY = frustumHeight / canvasHeight

  return { unitsPerPixelX, unitsPerPixelY }
}
