/* eslint react/no-unknown-property: 0 */

import { Mesh, MeshStandardMaterial } from 'three'

import React from 'react'
import { GLTF } from 'three-stdlib'
import { useGLTF } from '@react-three/drei'
import { I3DModelProps } from '../types'
import useScreenTexture from 'hooks/useScreenTexture'

type GLTFResult = GLTF & {
  nodes: {
    Body: Mesh
    Body_1: Mesh
    Body_2: Mesh
    Body_3: Mesh
    Body_4: Mesh
    Body_5: Mesh
    Body001: Mesh
    Body001_1: Mesh
    Body001_2: Mesh
    Body001_3: Mesh
    Body001_4: Mesh
    Body001_5: Mesh
    Cube017: Mesh
    Cube017_1: Mesh
    Cube017_2: Mesh
    Cube017_3: Mesh
    Screen001: Mesh
  }
  materials: {
    ['Material.001']: MeshStandardMaterial
    ['Material.002']: MeshStandardMaterial
    ['Material.003']: MeshStandardMaterial
    ['Material.004']: MeshStandardMaterial
    ['Material.005']: MeshStandardMaterial
    ['Material.006']: MeshStandardMaterial
    ['Material.007']: MeshStandardMaterial
    ['Material.009']: MeshStandardMaterial
    ['Material.010']: MeshStandardMaterial
    ['Material.011']: MeshStandardMaterial
    ['Material.012']: MeshStandardMaterial
    ['Material.013']: MeshStandardMaterial
    ['Material.014']: MeshStandardMaterial
    ['Material.015']: MeshStandardMaterial
    ['Material.016']: MeshStandardMaterial
  }
}

export default function LaptopModel({
  setRef = () => null,
  screenMedia,
  setScreenRef,
  screenProps,
  ...props
}: I3DModelProps) {
  const { nodes, materials } = useGLTF('/assets/3dmodels/laptop/laptop.glb') as GLTFResult

  const screenRef = useScreenTexture(screenMedia)

  return (
    <group ref={setRef} {...props} dispose={null}>
      <group position={[0, -0.93, 0]} rotation={[0.04, 0, 0]}>
        <mesh geometry={nodes.Body001.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Body001_1.geometry} material={materials['Material.011']} />
        <mesh geometry={nodes.Body001_2.geometry} material={materials['Material.012']} />
        <mesh geometry={nodes.Body001_3.geometry} material={materials['Material.014']} />
        <group ref={setScreenRef} position={[0, 0.1, -1.01]} {...screenProps}>
          <mesh geometry={nodes.Cube017.geometry} material={materials['Material.001']} />
          <mesh geometry={nodes.Cube017_1.geometry} material={materials['Material.007']} />
          <mesh geometry={nodes.Cube017_2.geometry} material={materials['Material.006']} />
          <mesh geometry={nodes.Screen001.geometry} position={[0, -0.1, 1.01]}>
            <meshBasicMaterial ref={screenRef} toneMapped={false} />
          </mesh>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/assets/3dmodels/laptop/laptop.glb')
