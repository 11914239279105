/* eslint react/no-unknown-property: 0 */

import { PerspectiveCamera } from '@react-three/drei'
import { ISceneProps } from './types'

const Scene = ({ cameraRef, cameraProps = {}, spotlightProps = {} }: ISceneProps) => {
  const lightColor = 'white'

  return (
    <>
      <PerspectiveCamera ref={cameraRef} makeDefault position={[0, 0, 10]} {...cameraProps} />
      <spotLight
        decay={0}
        color={lightColor}
        intensity={9}
        position={[0, 10, 5]}
        {...spotlightProps}
      />
    </>
  )
}

export default Scene
