/* eslint react/no-unknown-property: 0 */

import React, { useMemo } from 'react'
import { useGLTF } from '@react-three/drei'
import { Theme, useMediaQuery } from '@mui/material'

import { Mesh, MeshStandardMaterial } from 'three'
import { GLTF } from 'three-stdlib'
import { I3DModelProps } from '../types'
import useScreenTexture from 'hooks/useScreenTexture'

type GLTFResult = GLTF & {
  nodes: {
    back: Mesh
    d001: Mesh
    c001: Mesh
    a001: Mesh
    b001: Mesh
    e001: Mesh
    notch: Mesh
    Screen_frame: Mesh
    Screen: Mesh
    All: Mesh
    Volume: Mesh
    Lock: Mesh
    chip_a: Mesh
    chip_c: Mesh
    Structure001: Mesh
    chip_d: Mesh
    chip_b: Mesh
    c002: Mesh
    a002: Mesh
    b002: Mesh
    d002: Mesh
    antennae: Mesh
    Screws: Mesh
    Buttons: Mesh
    Structure: Mesh
  }
  materials: {
    ['jFPFAvCbiqflbQV.001']: MeshStandardMaterial
    ['KhJiSWFcsscOusf.001']: MeshStandardMaterial
    ['KtvhjlxyToKjYkE.001']: MeshStandardMaterial
    ['IDdMwJVCyuFpUnA.001']: MeshStandardMaterial
    ['sWxYOtHGWTcXRMx.001']: MeshStandardMaterial
    ['YiceMpFVTpnmoaq.001']: MeshStandardMaterial
    ['CSNzlRnZuvCyxNL.001']: MeshStandardMaterial
    ['vsSJQngPxBJTVZb.001']: MeshStandardMaterial
    ['IDdMwJVCyuFpUnA.001']: MeshStandardMaterial
    ['fdfRsQCrfvPBPfQ.001']: MeshStandardMaterial
    ['sWxYOtHGWTcXRMx.001']: MeshStandardMaterial
    ['WqbAhnIPgrrhfXS.001']: MeshStandardMaterial
    ['xHgtbqndQshkTKG.001']: MeshStandardMaterial
    ['LJBezuBxKRoHnAp.001']: MeshStandardMaterial
    ['IBtgGxCVyZhjKZM.001']: MeshStandardMaterial
    ['LtesZnUOMbBEAoi.001']: MeshStandardMaterial
    ['rNCplyWedyfORFh.001']: MeshStandardMaterial
    ['FsunUcGyajFpQmW.001']: MeshStandardMaterial
    ['nJRBoEsOhzMSqCz.001']: MeshStandardMaterial
    ['fGwijctGaiRaYJC.001']: MeshStandardMaterial
  }
}

export default function MobileHorizontalModel({
  setRef = () => null,
  screenMedia,
  leftPos = null,
  ...props
}: I3DModelProps) {
  const { nodes, materials } = useGLTF(
    '/assets/3dmodels/mobile/mobile_horizontal.glb',
  ) as GLTFResult
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const screenRef = useScreenTexture(screenMedia)

  const memoizedLeftPos = useMemo(() => {
    if (leftPos) {
      return leftPos
    }

    return isMobile ? 0.12 : 0
  }, [leftPos, isMobile])

  return (
    <group ref={setRef} {...props} dispose={null}>
      <group position={[memoizedLeftPos, -1.25, 0]} rotation={[0, 0.66, 0]}>
        <group rotation={[-Math.PI, 0.66, -Math.PI]} scale={0.16}>
          <mesh
            geometry={nodes.back.geometry}
            material={materials['jFPFAvCbiqflbQV.001']}
            position={[0, 7.9, 0]}
          />
        </group>
        <group rotation={[-Math.PI, 0.66, -Math.PI]} scale={0.16}>
          <mesh geometry={nodes.d001.geometry} material={materials['KhJiSWFcsscOusf.001']} />
          <mesh geometry={nodes.c001.geometry} material={materials['KtvhjlxyToKjYkE.001']} />
          <mesh geometry={nodes.a001.geometry} material={materials['IDdMwJVCyuFpUnA.001']} />
          <mesh geometry={nodes.b001.geometry} material={materials['sWxYOtHGWTcXRMx.001']} />
          <mesh geometry={nodes.e001.geometry} material={materials['fdfRsQCrfvPBPfQ.001']} />
          <mesh
            geometry={nodes.notch.geometry}
            material={materials['YiceMpFVTpnmoaq.001']}
            position={[0, 7.9, 0]}
          />
          <mesh
            geometry={nodes.Screen_frame.geometry}
            material={materials['CSNzlRnZuvCyxNL.001']}
            position={[0, 7.9, 0]}
          />
          <mesh geometry={nodes.Screen.geometry} position={[0, 7.9, 0]}>
            <meshBasicMaterial ref={screenRef} toneMapped={false} />
          </mesh>
        </group>
        <group rotation={[-Math.PI, 0.66, -Math.PI]} scale={0.16}>
          <mesh geometry={nodes.All.geometry} material={materials['KtvhjlxyToKjYkE.001']} />
          <mesh
            geometry={nodes.Volume.geometry}
            material={materials['WqbAhnIPgrrhfXS.001']}
            position={[0, 7.9, 0]}
          />
          <mesh
            geometry={nodes.Lock.geometry}
            material={materials['IBtgGxCVyZhjKZM.001']}
            position={[0, 7.9, 0]}
          />
          <mesh geometry={nodes.chip_a.geometry} material={materials['LtesZnUOMbBEAoi.001']} />
          <mesh geometry={nodes.chip_c.geometry} material={materials['KtvhjlxyToKjYkE.001']} />
          <mesh
            geometry={nodes.Structure001.geometry}
            material={materials['rNCplyWedyfORFh.001']}
            position={[0, 7.9, 0]}
          />
          <mesh geometry={nodes.chip_d.geometry} material={materials['WqbAhnIPgrrhfXS.001']} />
          <mesh geometry={nodes.chip_b.geometry} material={materials['IBtgGxCVyZhjKZM.001']} />
          <mesh
            geometry={nodes.c002.geometry}
            material={materials['LJBezuBxKRoHnAp.001']}
            position={[0, 7.9, 0]}
          />
          <mesh
            geometry={nodes.b002.geometry}
            material={materials['FsunUcGyajFpQmW.001']}
            position={[0, 7.9, 0]}
          />
          <mesh geometry={nodes.d002.geometry} material={materials['nJRBoEsOhzMSqCz.001']} />
          <mesh
            geometry={nodes.a002.geometry}
            material={materials['fGwijctGaiRaYJC.001']}
            position={[0, 7.9, 0]}
          />
          <mesh
            geometry={nodes.antennae.geometry}
            material={materials['LJBezuBxKRoHnAp.001']}
            position={[0, 7.9, 0]}
          />
          <mesh
            geometry={nodes.Screws.geometry}
            material={materials['vsSJQngPxBJTVZb.001']}
            position={[0, 7.9, 0]}
          />
          <mesh
            geometry={nodes.Buttons.geometry}
            material={materials['xHgtbqndQshkTKG.001']}
            position={[0, 7.9, 0]}
          />
        </group>
        <mesh
          geometry={nodes.Structure.geometry}
          material={materials['KtvhjlxyToKjYkE.001']}
          position={[0, 1.25, 0]}
          rotation={[-Math.PI, 0.66, -Math.PI]}
          scale={0.16}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/assets/3dmodels/mobile/mobile_horizontal.glb')
