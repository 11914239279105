import { IScreenMedia } from 'public/assets/3dmodels/types'
import { useEffect, useRef } from 'react'
import { MeshBasicMaterial, Texture } from 'three'
import { getVideoTexture, getImageTexture } from 'utils/threejsUtils'

const useScreenTexture = (screenMedia: IScreenMedia) => {
  const screenRef = useRef<MeshBasicMaterial | null>(null)
  const textureRef = useRef<Texture | null>(null)
  const contentIdRef = useRef<string | null>(null)

  useEffect(() => {
    if (!screenMedia || !screenRef.current) return

    const contentId = screenMedia.contentId || screenMedia.src || 'unknown'
    
    if (contentIdRef.current !== contentId) {
      if (textureRef.current && screenRef.current.map === textureRef.current) {
        screenRef.current.map = null
        textureRef.current.dispose()
        textureRef.current = null
      }
      
      contentIdRef.current = contentId

      let newTexture: Texture | null = null
      
      if (screenMedia.type === 'video') {
        newTexture = getVideoTexture(screenMedia)
      } else if (screenMedia.type === 'image') {
        newTexture = getImageTexture(screenMedia)
      }
      
      if (newTexture) {
        textureRef.current = newTexture
        screenRef.current.map = newTexture
        screenRef.current.needsUpdate = true
      }
    }
  }, [screenMedia])

  useEffect(() => {
    return () => {
      if (textureRef.current) {
        textureRef.current.dispose()
        textureRef.current = null
      }
    }
  }, [])

  return screenRef
}

export default useScreenTexture
