import { Canvas as ThreejsCanvas, CanvasProps } from '@react-three/fiber'

const Canvas = ({
  children,
  canvasProps = {},
}: {
  children: JSX.Element | JSX.Element[]
  canvasProps?: CanvasProps
}) => {
  return (
    <ThreejsCanvas
      dpr={2}
      gl={{
        antialias: true,
        preserveDrawingBuffer: true,
        powerPreference: 'high-performance',
      }}
      {...canvasProps}
    >
      {children}
    </ThreejsCanvas>
  )
}

export default Canvas
